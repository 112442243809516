// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-autofill-js": () => import("./../../../src/pages/autofill.js" /* webpackChunkName: "component---src-pages-autofill-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fastql-js": () => import("./../../../src/pages/fastql.js" /* webpackChunkName: "component---src-pages-fastql-js" */),
  "component---src-pages-grwo-js": () => import("./../../../src/pages/grwo.js" /* webpackChunkName: "component---src-pages-grwo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

